<template>
	<v-container
		fluid
		v-if="!hasAnyPermission"
		class="pa-10"
		id="regular-tables"
		tag="section"
	>
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat> </v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			hasAnyPermission: true,
		};
	},
	created() {
		if (this.hasPermission("View Company Profile Page")) {
			this.$router.push("company-profile");
		} else if (this.hasPermission("View Role")) {
			this.$router.push("roles");
		} else if (this.hasPermission("View Helpdesk Page")) {
			this.$router.push("helpdesk");
		} else {
			this.hasAnyPermission = false;
		}
	},
};
</script>

<style></style>
